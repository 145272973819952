import './ecomode';
import './animations';

import toggleFn from './functions/toggle';
import isInViewport from './functions/in_viewport';

import gsap from 'gsap';

import isMobile from 'ismobilejs';
import customSelect from './functions/select-custom';

const is_mobile = isMobile().phone || window.innerWidth <= 999;

let toggle = null;
window.addEventListener('DOMContentLoaded', () => {
  if (!is_mobile) {
    customSelect();
  }

  buttonApplyHandle();
  toggleFn();
  videoAutoHandle();
  stuckTopFn();
  heritageArrows();
  offersVideo();
  menuMobileHandle();
  searchLoad();

  const title = document.querySelector('.title--cover');
  if (Math.random() > .5 && title) {
    title.classList.add('show--first');
  }
});

/****
 *    Function : buttonApplyHandle
 *    Usage : this function handles the scroll animation for the Apply button
 ****/
const buttonApplyHandle = () => {
  const button = document.querySelector('.button--apply');
  if (button) {
    const wrapper = document.querySelector('.offer--apply');
    const offerTitle = document.querySelector('.offer--title');

    window.addEventListener('scroll', () => {
      //If the button enters the screen
      if (wrapper.getBoundingClientRect().bottom <= window.innerHeight) {
        //Unstick the button
        button.classList.remove('stuck');
      }
      else {
        //If the button wrapper leaves the screen, stick the button
        if (!isInViewport(offerTitle) && wrapper.getBoundingClientRect().bottom >= window.innerHeight) {
          button.classList.add('stuck');
        }
      }
    });
  }
};

/****
 *    Function : videoAutoHandle
 *    Usage : this function initialize YouTube API and displaying the popin
 ****/
const videoAutoHandle = () => {
  const controls = document.querySelectorAll('.video-controls');
  const YTLoaded = false;
  const events = ['mouseenter', 'focus'];
  if (Object.values(controls).length > 0) {
    Object.values(controls).map(control => {
      events.map(event => {
        control.addEventListener(event, () => {
          if (!YTLoaded) {
            // This code loads the IFrame Player API code asynchronously. This is the Youtube-recommended script loading method
            const tag = document.createElement('script');

            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          }
        });
      });
    });
  }

  Object.values(controls).map(control => {
    const videoWrapper = control.closest('.video--box,.media-thumbnail-box,.video--large').querySelector('.player');
    const close = control.closest('.video--box,.media-thumbnail-box,.video--large').querySelector('.button--close');
    let video = null;

    //This code toggles trigger the videoYTInit function which loads the player.
    control.addEventListener('click', () => {
      if (!video) {
        video = videoYTInit(videoWrapper.id);
      }//If the video already exists, we change the state to "play"
      else {
        video.playVideo();
      }

      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && control.classList.contains('active')) {
          close.click();
        }
      });
    });

    //This code changes the state to "pause" when clicking the "Close" button.
    if (close) {
      close.addEventListener('click', () => {
        video.pauseVideo();
      });
    }
  });
};

/****
 *    Function : videoYTInit
 *    Usage : this function uses YT API Player method to load the YouTube player.
 ****/
const videoYTInit = (id) => {
  return new YT.Player(id, {
    height: '320',
    width: '320',
    videoId: id,
    host: 'https://www.youtube-nocookie.com',
    events: {
      // API event handlers on ready
      onReady: onPlayerReady,
    },
  });
};

/****
 *    Function : videoYTInit
 *    Usage : this function plays the YouTube video.
 ****/
function onPlayerReady(event) {
  event.target.playVideo();
}

/****
 *    Function : stuckTopFn
 *    Usage : this function calculates the top value for stuck element in the DOM.
 ****/
const stuckTopFn = () => {
  const header = document.querySelector('.main-header');
  const elements = document.querySelectorAll('.stuck--top');

  //This code loops on every element which className contains "stuck--top"
  Object.values(elements).map(element => {
    gsap.set(element, {
      //This code calculate the top value according to the main Header height and a fixed margin of 20
      top: header.getBoundingClientRect().height + 20,
    });
  });
};

/****
 *    Function : heritageArrows
 *    Usage : this function displays the ticker event on homepage with an animation.
 ****/
const heritageArrows = () => {
  const heritageSection = document.querySelector('.section--heritage');

  if (heritageSection) {
    const heritageArrows = document.querySelector('.heritage--navigation');
    placeArrows(heritageArrows, heritageSection);

    window.addEventListener('scroll', () => {
      placeArrows(heritageArrows, heritageSection);
    });
  }
};

const placeArrows = (arrows, section) => {
  const rectSection = section.getBoundingClientRect();
  gsap.set(arrows, {
    position: rectSection.bottom <= window.innerHeight ? 'absolute' : 'fixed',
  });
};

const menuMobileHandle = () => {
  const button = document.querySelector('[data-toggle-menu]');
  const nav = document.querySelector('[data-toggle-element-menu]');
  const buttons = document.querySelector('.mobile--buttons');

  if (button) {
    button.addEventListener('click', () => {
      console.log('ok');
      nav.classList.toggle('open');
      button.classList.toggle('active');
    });
  }
};

const offersVideo = () => {
  const iframes = document.querySelectorAll('.section--offers iframe');

  Object.values(iframes).map(iframe => {
    console.log(iframe.closest('.lumesse-video-wrapper'));
    if (!iframe.closest('.lumesse-video-wrapper')) {
      const wrapper = document.createElement('DIV');
      wrapper.classList.add('lumesse-video-wrapper');

      iframe.parentElement.appendChild(wrapper);
      wrapper.appendChild(iframe);
    }

  });
};

const searchLoad = () => {
  const header = document.querySelector('.main-header');

  const events = ['mouseenter', 'focus'];

  events.map(event => {
    header.addEventListener(event, async () => {
      const searchButton = document.querySelector('[data-toggle="search"]');

      if (document.querySelector('.search--wrapper')) {
        return;
      }

      const wrapper = await fetch('/wp-json/search/v1/module', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          get: searchButton.dataset.search,
        }),
      }).then(resp => resp.text());

      const div = document.createElement('DIV');
      div.innerHTML = wrapper;
      document.body.appendChild(div);

      toggleFn('.search--wrapper .button--close');
    });
  });
};
