import parseCookie, {setCookie} from './functions/parseCookies';
import animations from './animations';

// Handler
const ecomodePane = {
  init: () => {
    const buttons = document.querySelectorAll('[data-toggle-ecomode]');

    console.log(buttons)
    Object.values(buttons).map(button =>
      button.addEventListener('click', async () => {
        const nav = document.querySelector('[data-toggle-element-ecomode]');
        nav.classList.toggle('open');

        //set up accesibility
        if (!nav.classList.contains('open')) {
          buttons[0].focus();
        }

        [...document.querySelectorAll('.ecomode--wrapper input, .ecomode--wrapper button')].map(item => {
          item.setAttribute('tabindex', nav.classList.contains('open') ? 0 : -1)
        });
      }),
    );

    ecomodePane.checkboxes();
  },
  loadModule: () => {
    const header = document.querySelector('.main-header');
    const buttons = document.querySelectorAll('[data-toggle-ecomode]');

    if (buttons.length > 0) {
      [...buttons].map(button => {
        button.addEventListener('click', async () => {
          if (!header.querySelector('.ecomode--wrapper')) {
            button.classList.add('loading');

            const ecomodeWrapper = await fetch('/wp-json/ecomode/v1/module').then(resp => resp.text());
            const wrapper = document.createElement('DIV');
            wrapper.innerHTML = ecomodeWrapper;
            button.after(wrapper);

            setTimeout(() => {
              header.querySelector('.ecomode--wrapper').classList.toggle('open');
              ecomodePane.init();


              button.classList.remove('loading');
            }, 200);
          }
        });
      })
    }
  },
  checkboxes: () => {
    const checkboxAll = document.querySelector('.form--ecomode input[type=checkbox][name*="all"]');
    const checkboxesCustom = document.querySelectorAll('.form--ecomode input[type=checkbox]:not([name*="all"])');

    if (checkboxAll) {
      checkboxAll.addEventListener('change', () => {
        Object.values(checkboxesCustom).map(item => {
          item.checked = checkboxAll.checked;
          triggerEvent(item, 'change');
        });
      });
    }

    Object.values(checkboxesCustom).map(item => {
      item.addEventListener('change', () => {
        if (!item.checked) {
          checkboxAll.checked = false;
        }

        ecomode.update(item);
      });
    });
  },
};

const ecomode = {
  init: (once) => {
    let cookies = '';
    let data = {dark: false, animations: false};

    if (document.cookie !== '') {
      cookies = parseCookie(document.cookie);

      data = {
        dark: cookies['ecomode-dark'] === 'true',
        animations: cookies['ecomode-animations'] === 'true',
        images: cookies['ecomode-images'] === 'true',
        fonts: cookies['ecomode-fonts'] === 'true',
      };
    }

    if (data) {
      const r = document.querySelector(':root');
      const b = document.querySelector('body');

      Object.keys(data).map(index => {

        //If is true
        if (data[index] === true) {
          document.body.classList.add('no-' + index);

          if (index === 'dark') {
            r.style.setProperty('--bg', '#383838');
            r.style.setProperty('--primary', '#383838');
            b.style.setProperty('--wp--preset--color--primary', '#383838');
          }

          if (index === 'animations') {
            animations(true);
          }

          if (index === 'images') {
            console.log(index);
            ecomode.lazyload(true);
          }

          if (index === 'fonts') {
            const fontsCSS = document.getElementById('styles_fonts');
            if (fontsCSS) {
              fontsCSS.media = 'none';
            }
          }

          if (once) {
            const input = document.querySelector(`.form--ecomode input[name="ecomode-${index}"]`);
            //input.checked = true;
            //triggerEvent(input, 'change');
          }
        }
        else {
          document.body.classList.remove('no-' + index);

          if (index === 'dark') {
            r.removeAttribute('style');
            b.removeAttribute('style');
          }

          if (index === 'animations') {
            animations(false);
          }

          if (index === 'images') {
            ecomode.lazyload(false);
          }

          if (index === 'fonts') {
            const fontsCSS = document.getElementById('styles_fonts');
            if (fontsCSS) {
              fontsCSS.media = 'all';
            }
          }
        }
      });
    }
  },
  update: (item) => {
    const ecomodeData = localStorage.getItem('ecomode') || JSON.stringify({});

    if (ecomodeData) {
      setCookie(item.name, item.checked, 7);
      ecomode.init();
    }
  },
  lazyload: (disabled) => {
    const imagesLazy = document.querySelectorAll('img:not([data-skip-lazy]):not(.no-eco):not(.custom-logo), iframe[data-rocket-lazyload]:not(.no-eco)');
    const imagesSources = document.querySelectorAll('figure:not(.no-eco) source');

    Object.values(imagesLazy).map(item => {
      if (disabled) {
        const itemWidth = parseFloat(item.getAttribute('width'));
        const itemHeight = parseFloat(item.getAttribute('height'));
        const fallback = `data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20${itemWidth || 1}%20${itemHeight || itemWidth || 1}'%20width='${itemWidth || 1}px'%20height='${itemHeight || itemWidth || 1}'%3E%3C/svg%3E`;

        if ((item.dataset.lazySrc || item.src) !== fallback) {
          item.dataset.oldLazySrc = (item.dataset.lazySrc || item.src);
          item.dataset.oldLazySrcset = (item.dataset.lazySrcSet || item.src);
        }

        item.dataset.lazySrc = fallback;
        item.dataset.lazySrcset = fallback;
        item.src = fallback;
        item.srcset = fallback;

        item.classList.add('eco');
      }
      else {
        if (item.dataset.oldLazySrc) {
          item.dataset.lazySrc = item.dataset.oldLazySrc;

          item.src = item.dataset.oldLazySrc;
          item.srcset = item.dataset.oldLazySrc;
        }

        if (item.dataset.oldLazySrcset) {
          item.dataset.lazySrcset = item.dataset.oldLazySrcset;
        }

        item.classList.remove('eco');
      }
    });

    Object.values(imagesSources).map(item => {
      if (disabled) {
        item.dataset.type = item.type;
        item.type = 'none';
      }
      else {
        if (item.dataset.type) {
          item.type = item.dataset.type;
        }
      }
    });
  },
};
window.addEventListener('DOMContentLoaded', () => {
  ecomodePane.loadModule();
  ecomode.init(true);
});

function triggerEvent(element, eventName) {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(eventName, false, true);
  element.dispatchEvent(event);
}

export default ecomode;
