export default function toggleFn(element) {
  let buttons = document.querySelectorAll('[data-toggle]');

  if (element) {
    buttons = document.querySelectorAll(element);
  }

  [...buttons].map(button => {
    button.addEventListener('click', () => {
      const id = button.dataset.toggle;
      const elementsToToggle = document.querySelectorAll(`[data-toggle-element=${id}]`);

      [...document.querySelectorAll(`[data-toggle=${id}]`)].map(item => item.classList.toggle('active'));

      [...elementsToToggle].map(item => {
        item.classList.toggle('toggled');

        if (id === 'search') {
          item.querySelector('input').focus();
        }
      });
    });
  });

  window.addEventListener('click', e => {
    if (!e.target.closest('[data-toggle]') && !e.target.closest('[data-toggle-element]')) {
      [...document.querySelectorAll(`[data-toggle]:not([data-toggle-persistent]).active`)].map(item => {
        item.classList.remove('active');
      });

      [...document.querySelectorAll(`[data-toggle-element]:not([data-toggle-persistent]).toggled`)].map(item => {
        item.classList.remove('toggled');
      });
    }
  });
};

