const parseCookie = str =>
  str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});

export function setCookie(name, value, days, secure) {
  let expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    expires = '; expires='+date.toGMTString();
  }
  const domain = null;
  console.log(name + '='+escape(value) + expires + '; path=/' + (domain ? '; domain=' + domain : '') + ((secure && locProtocol == 'https:') ? '; secure' : ''))
  document.cookie = name + '='+escape(value) + expires + '; path=/' + (domain ? '; domain=' + domain : '') + ((secure && locProtocol == 'https:') ? '; secure' : '');
}


export default parseCookie;
