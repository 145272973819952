import isMobile from 'ismobilejs';

export default function customSelect() {
  const selects = document.querySelectorAll('[data-custom-select]');

  Object.values(selects).map((select, index) => {
    const options = select.querySelectorAll('option');
    const input = select.querySelector('input');

    const _select = select.querySelector('select');

    //Multiple select handling
    let is_multiple = _select.multiple;

    const selectInner = select.querySelector('[data-custom-select-inner]');

    const selectPane = document.createElement('DIV');
    selectPane.classList.add('custom-select--pane');

    selectInner.setAttribute('data-toggle', 'custom-select-' + index);
    selectPane.setAttribute('data-toggle-element', 'custom-select-' + index);
    selectPane.setAttribute('tabindex', -1);

    const placeholder = select.dataset.customSelectPlaceholder || '';

    let selectLabel = null;
    if (placeholder) {
      selectLabel = document.createElement('SPAN');
      selectLabel.classList.add('custom-select--label');
      selectLabel.innerText = select.querySelector('option[selected]') ? '' : placeholder;

      const selected = select.querySelectorAll('option[selected]');
      [...selected].map((item, index) => selectLabel.innerText += item.innerText + (index < selected.length - 1 ? ' - ' : ''));

      selectInner.appendChild(selectLabel);
    }

    const optionsListing = document.createElement('UL');
    Object.values(options).map(option => {
      if (option.value !== '') {
        const li = document.createElement('LI');
        li.innerHTML = option.innerHTML.trimStart();
        li.dataset.value = option.value;

        if (option.defaultSelected) {
          option.setAttribute('selected', true);
          li.classList.add('selected');
          select.classList.add('select--edited');
        }

        optionsListing.appendChild(li);

        li.addEventListener('click', () => {
          if (li.classList.contains('selected')) {
            li.classList.remove('selected');
          }
          else {
            li.classList.add('selected');
          }

          if (!is_multiple) {
            _select.value = li.dataset.value;
          }
          else {
            const option = document.querySelector(`select option[value="${li.dataset.value}"]`);

            if (li.classList.contains('selected')) {
              option.setAttribute('selected', true);
            }
            else {
              option.removeAttribute('selected');
            }
          }

          _select.dispatchEvent(new KeyboardEvent('change'));
        });
      }
    });

    _select.closest('fieldset').style.opacity = 1;

    selectPane.appendChild(optionsListing);
    select.appendChild(selectPane);

    _select.addEventListener('change', () => {
      if (!is_multiple) {
        [...selectPane.querySelectorAll('li.selected')].map(item => {
          item.classList.remove('selected');
        });

        const elementToActivate = selectPane.querySelector(`li[data-value="${_select.value}"]`);
        elementToActivate?.classList.add('selected');

        if (selectLabel) {
          selectLabel.innerText = elementToActivate.innerText;
        }

        input.value = _select.value;
        _select.click();
      }
      else {
        const label = [];
        const values = [];

        [..._select.selectedOptions].map(option => {
          const elementToActivate = selectPane.querySelector(`li[data-value="${option.value}"]`);
          elementToActivate?.classList.add('selected');
          label.push(option.innerText);
          values.push(option.value);
        });

        if (selectLabel) {
          selectLabel.innerText = label.join(' - ');
        }
        input.value = `${values.join(',')}`;
      }

      if (_select.value !== '') {
        select.classList.add('select--edited');
      }
      else {
        select.classList.remove('select--edited');
      }

      if (selectLabel) {
        if (selectLabel.innerText === '') {
          selectLabel.innerText = placeholder;
        }
      }
    });

    //Accessibility handle
    const accessibility_events = ['focus', 'blur'];
    accessibility_events.map(event => {
      _select.addEventListener(event, () => {
        if (selectInner.classList.contains('active')) {
          console.log(_select);
          selectInner.click();
        }
      });
    });
  });
}
