import AOS from 'aos';
import parseCookie from './functions/parseCookies';

const animations = (disabled = false) => {
  const elements = document.querySelectorAll(disabled ? '[data-aos]' : '[data-aos-no]');

  Object.values(elements).map(item => {
    item.setAttribute(disabled ? 'data-aos-no' : 'data-aos', disabled ? item.dataset.aos : item.dataset.aosNo);
    item.removeAttribute(disabled ? 'data-aos' : 'data-aos-no');
  });

  if (!disabled) {
    aosInit();
  }
};

window.addEventListener('DOMContentLoaded', () => {
  let cookies;

  if (document.cookie !== '') {
    cookies = parseCookie(document.cookie);

    animations(cookies['ecomode-animations'] === 'true');

    if (cookies['ecomode-animations'] === 'true') {
      aosInit();
    }
  }
  else {
    aosInit();
  }
});
const aosInit = () => {
  AOS.init({
    once: false,
  });
};

export default animations;
